<template>
  <div class="py-md-8 py-6">
    <v-row v-if="paymentError">
      <v-col lg="6" md="12">
        <v-alert
          text
          dense
          type="error"
          icon="mdi-alert-octagon-outline"
          dismissible
        >
          {{ paymentError?.message }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" md="12">
        <label class="text-subtitle-2 gray--text text--darken-2">
          {{ $t("createOrder.deposit.form.cardInformationSectionTitle") }}
        </label>
        <v-row class="mt-2">
          <v-col cols="12" class="mb-2">
            <div class="stripe--input" id="card-number"></div>
          </v-col>
          <v-col cols="6" md="6" class="mb-0 ps-0 pe-2">
            <div class="stripe--input" id="card-expiry"></div>
          </v-col>
          <v-col cols="6" md="6" class="mb-0 ps-0">
            <div class="stripe--input" id="card-cvc"></div>
          </v-col>

          <v-col cols="12" class="mobile-right-pane">
            <s-apply-coupon v-if="!appliedCoupon" />
          </v-col>

          <v-col cols="12" class="ps-0">
            <v-btn
              type="submit"
              color="primary"
              class="rounded-0 mt-md-8 mt-6 sm-full-width"
              depressed
              @click="handleSubmit"
              :loading="submitting"
              large
            >
              {{ $t("createOrder.deposit.actions.pay") }} {{ depositAmount }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  calculateDepositAmount,
  calculateDepositAmountAfterDiscount,
  calculateTotalAmount,
  usdCurrencyFormatter
} from "@/utils/payment";
import ApplyCoupon from "@/components/create-order/ApplyCoupon.vue";

const style = {
  base: {
    color: "#424242",
    fontFamily: "\"Poppins\", sans-serif",
    fontSize: "16px"
  },
  invalid: {
    color: "#FF5252",
    iconColor: "#fa755a"
  }
};

export default {
  components: {
    "s-apply-coupon": ApplyCoupon
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      submitting: false,
      stripe: null,
      paymentError: null
    };
  },
  computed: {
    ...mapState("KitchenLayout", {
      sqft: state => state.sqft
    }),
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone
    }),
    ...mapState("KitchenDetails", {
      hasOldCountertopRemoval: state => state.hasOldCountertopRemoval,
      selectedCutouts: state => state.selectedCutouts
    }),
    ...mapState("Deposit", {
      appliedCoupon: state => state.appliedCoupon
    }),
    depositAmount() {
      const totalAmount = calculateTotalAmount(this.selectedStone, this.sqft, this.hasOldCountertopRemoval, this.selectedCutouts);
      let depositAmount = calculateDepositAmount(totalAmount);
      if (this.appliedCoupon) {
        depositAmount = calculateDepositAmountAfterDiscount(totalAmount, this.appliedCoupon);
      }
      return this.formatDecimal(depositAmount);
    },
    stripeElements() {
      return this.stripe.elements();
    }
  },
  mounted() {
    this.cardNumber = this.stripeElements.create("cardNumber", {
      style,
      showIcon: true,
      placeholder: "1234 1234 1234 1234"
    });
    this.cardNumber.mount("#card-number");

    this.cardExpiry = this.stripeElements.create("cardExpiry", {
      style,
      placeholder: "MM / YY"
    });
    this.cardExpiry.mount("#card-expiry");

    this.cardCvc = this.stripeElements.create("cardCvc", {
      style,
      placeholder: "CVC"
    });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber && this.cardNumber.destroy();
    this.cardExpiry && this.cardExpiry.destroy();
    this.cardCvc && this.cardCvc.destroy();
  },
  methods: {
    formatDecimal: usdCurrencyFormatter.format,
    async handleSubmit() {
      const clientSecret = localStorage.getItem(this.$constants.DEPOSIT_STRIPE_PAYMENT_CLIENT_SECRET_STORAGE_KEY);
      this.paymentError = null;
      this.submitting = true;

      const { paymentIntent, error } = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.cardNumber,
          billing_details: {}
        }
      });

      if (error) {
        this.paymentError = error;
      }

      if (paymentIntent?.status === "succeeded") {
        this.$router.push("/order-countertop/schedule-measurement");
        localStorage.clear();
      }

      this.submitting = false;
    }
  },
  created() {
    this.stripe = window.Stripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    );
  }
};
</script>